import * as React from "react";
import Slider from "react-slick";

import Layout from "../../../components/layout";
import BottomInquiry from "../../../components/bottom-inquiry";
import { StaticImage } from "gatsby-plugin-image"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductDetails = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "image-list"
  };

  return (
    <Layout>
      <section className="product-details">
        <div className="container main-layout">
          <div className="row">
            <div className="col">
              <Slider {...settings}>
                  <StaticImage alt="events image" src="../../../images/1.jpg" className="product-slider"/>
                  <StaticImage alt="events image" src="../../../images/2.jpg" className="product-slider"/>
                  <StaticImage alt="events image" src="../../../images/3.jpg" className="product-slider"/>
                  <StaticImage alt="events image" src="../../../images/4.jpg" className="product-slider"/>
                  <StaticImage alt="events image" src="../../../images/5.jpg" className="product-slider"/>
                  <StaticImage alt="events image" src="../../../images/6.jpg" className="product-slider"/>
                  <StaticImage alt="events image" src="../../../images/7.jpg" className="product-slider"/>
              </Slider>
              <div className="summary">
                <h5>Z-09</h5>
                <div className="row">
                  <div className="col-6 col-md-4 col-lg-2 row-info">
                    <div className="info-label">Calibre</div>
                    <div className="info-body">9x21 mm</div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 row-info">
                    <div className="info-label">Thread pitch</div>
                    <div className="info-body">1:10"</div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 row-info">
                    <div className="info-label">Barrel</div>
                    <div className="info-body">13” (330 mm.) Stainless</div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 row-info">
                    <div className="info-label">Weight</div>
                    <div className="info-body">3kg (without store)</div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 row-info">
                    <div className="info-label">Accuracy</div>
                    <div className="info-body">&#60;4 MOA</div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 row-info">
                    <div className="info-label">Length</div>
                    <div className="info-body">810 mm</div>
                  </div>
                </div>
              </div>
              <p className="body-title">Product description</p>
              <div className="body-text">
                <p>Hunting self-loading carbine "Z-9". Free-hanging barrel 13 "(330 mm) long is made of stainless steel. The contour is light. The pitch of the barrel is 1:10", muzzle thread 5 / 8-24. Receivers are made by forging and subsequent processing on CNC machines. Material - aluminum 7075-T6. The coating is a solid black anode. 11 "(280 mm) lightweight aluminum alloy stream with KeyMod mounting system. It has an integrated 12-hour Picatinny bar with the option of installing additional bars or additional equipment, with a KeyMod mounting system.</p>

                <p>The carbine is equipped with a standard single-stage trigger mechanism, three-chamber muzzle brake compensator (mounted on a fixed thread clamp), one magazine with a limit of 10 rounds, double-sided reloading handles and safety, cover for storage and carrying. Sand-colored cover, material combined: 1000d / nylon cordura. Carbine automation works on the principle of free shutter. * Warranty period is 1 year or 10,000 shots from the moment of sale. The complete set and appearance of a product can change, the muzzle device is mounted on a fixed thread clamp.</p>
                
                <p>Self-disassembly of the muzzle device can lead to the destruction of the carbine! The product warranty applies only to the use of high-quality factory hunting ammunition. Given the completeness of the product with a stainless steel barrel, the use of cartridges with a bimetallic ball can significantly reduce the life of the barrel. In this case, the critical wear of the barrel can not be regarded as a warranty case.</p>
              </div>
            </div>
          </div>
        </div>
        <BottomInquiry></BottomInquiry>
      </section>
    </Layout>
  )
}

export default ProductDetails